import React, { useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import { useParams,useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import CarouselSlider from './CarouselSlider';
import eventsData from './Events.json';

function EventView(props) {
  const params = useParams();
  const [events, setEvents] = useState([]);
  const [slideflag, setflag] = useState(true);
  const [content, setContent] = useState('');

  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const handleImageClick = (image) => {
    setModalImage(image);
    setShowModal(true);
  };

  useEffect(() => {
    getEvents(params.slug);
  }, [params.slug]);

  const getEvents = async (title) => {
    props.loderFunction(true);
    try {
      const res = {};
      let data = [];
      data = eventsData.filter(event => (event.blog_title.toLowerCase().replace(/\s+/g, '')) === title);
      setContent(data[0].description);
      const images = data[0].image;
      if (Array.isArray(images)) {
        var dataWithImages = data.map(event => ({
        ...event.image,
          image: Array.isArray(event.image) 
            ? event.image.map(img => `${process.env.PUBLIC_URL}${img}`) 
            : `${process.env.PUBLIC_URL}${event.image}` 
        }));
        res.data = dataWithImages[0].image;     
      }
      else{
        var dataWithImages = data.map(event => ({
        ...event,
          image: Array.isArray(event.image) 
            ? event.image.map(img => `${process.env.PUBLIC_URL}${img}`) 
            : `${process.env.PUBLIC_URL}${event.image}` 
         }));
        setflag(false)
        res.data = dataWithImages;
      }
      setEvents(res.data);
    } catch (err) {
      props.loderFunction(false);
    }
    props.loderFunction(false);
  };

  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className="bk-slider">
      <button class="btn btn-secondary" onClick={handleBack}>Back</button>
      <article>
        <p className="short-description-font m-0 mt-3" dangerouslySetInnerHTML={{ __html: content }}></p>
      </article>
      {slideflag 
        ? 
        <CarouselSlider images={events}/> 
        : 
        <p>
          <div className="band">
            {events.map((item, ind) => {
              return (
                <div className={`item-${ind}`}  key={ind} >
                  <a className="my-card">
                    <section className="mt-5">
                      <div className="image-container">
                        <img src={item.image} alt={item.blog_title} className="slider-image"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleImageClick(item?.image);
                        }}
                        />
                        <div className="image-title">{(item.image).substring((item.image).lastIndexOf("/") + 1, (item.image).lastIndexOf("."))}</div>
                      </div>
                    </section>
                  </a>
                </div>
              );
            })}
          </div>
          <Modal show={showModal} onHide={() => setShowModal(false)} centered dialogClassName="singleModal">
            <Modal.Body>
            <img className="w-500 mh-100 img-fluid rounded" src={modalImage} alt="Mamallapuram Event"/>
            </Modal.Body>
            <Modal.Footer>
              <div className="image-title-footer">{modalImage.substring(modalImage.lastIndexOf("/") + 1, modalImage.lastIndexOf("."))}</div>
            </Modal.Footer>
          </Modal>
        </p>
      }
    </div>
  );
};

export default EventView;
