import React, { useState, useRef } from "react";
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

const PrevArrow = ({ className, onClick }) => {
  return (
    <div className={`${className} custom-arrow prev-arrow`} onClick={onClick}></div>
  );
};

const NextArrow = ({ className, onClick }) => {
  return (
    <div className={`${className} custom-arrow next-arrow`} onClick={onClick}></div>
  );
};

const CarouselSlider = ({ images, clickable }) => {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const showDots = images.length <= 10;
  const settings = {
    dots: showDots,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true, // Enables partial slide visibility
    centerPadding: '50px', // Adjusts the visibility of side slides
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    autoplay: true,  // Enable autoplay
    autoplaySpeed: 2000,  // Slide automatically every 3 seconds 
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          centerPadding: '50px',
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: '50px',
        },
      },
    ],
  };
  
  const handleImageClick = (page_url) => {
    navigate(`/${page_url}`);
  };
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const modelClick = (image) => {
    setModalImage(image);
    setShowModal(true);
    if (sliderRef.current) {
      sliderRef.current?.slickPause();
    }
  };

  const closeModal = () => {
    setShowModal(false);
    if (sliderRef.current) {
      // sliderRef.current.slickPlay(); 
      sliderRef.current?.slickPlay();
    }
  };

  return (
    <div className="carousel-slider">
      <Slider {...settings} ref={sliderRef}>
        {clickable
          ? images.map((event, index) => (
            <div key={index} className="image-container">
              <img src={event.image} alt={event.blog_title} className="slider-image" onClick={() => handleImageClick(event.page_url)} />
              <div className="image-title">{event.blog_title}</div>
            </div>
          ))
          : images.map((event, index) => (
            <div key={index} className="image-container"> 
              <img src={event} alt={event.substring(event.lastIndexOf("/") + 1, event.lastIndexOf("."))} className="slider-image" onClick={() => modelClick(event)}/>
              <div className="image-title">{event.substring(event.lastIndexOf("/") + 1, event.lastIndexOf("."))}</div>
            </div>
          ))
        }
      </Slider>
      <Modal show={showModal} onHide={closeModal} centered>
        <Modal.Body>
          <img className="w-100 mh-100 img-fluid rounded" src={modalImage} alt="Trail Event"/>
        </Modal.Body>
       <Modal.Footer>
          <div className="image-title-footer">{modalImage.substring(modalImage.lastIndexOf("/") + 1, modalImage.lastIndexOf("."))}</div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CarouselSlider;
