import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import eventsData from './Events.json'; 
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

function Tour_Trail(props) {
  const params = useParams();
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [selectedYear, setSelectedYear] = useState("All");
  const itemsPerPage = 8; 
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastImage = currentPage * itemsPerPage;
  const indexOfFirstImage = indexOfLastImage - itemsPerPage;
  const currentImages = filteredEvents.slice(indexOfFirstImage, indexOfLastImage);

  const handleNextPage = () => {
    if (currentPage < Math.ceil(events.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleImageClick = (page_url) => {
    navigate(`/${page_url}`);
  };

  const handleSelect = (year) => {
    setSelectedYear(year);
    setCurrentPage(1); 
    if (year === "All") {
      setFilteredEvents(events);
    } else {
      setFilteredEvents(events.filter(event => event.year.toString() === year));
    }
  };

  useEffect(() => {
    getEvents(params.slug);
  }, [params.slug]);

  const getEvents = async (category) => {
    props.loderFunction(true);
    let data = eventsData.filter(event => event.category === "tour&trail");
    const dataWithImages = data.map(event => ({
      ...event,
      image: Array.isArray(event.image)  
          ?`${process.env.PUBLIC_URL}${event.image[0]}`
        : `${process.env.PUBLIC_URL}${event.image}` 
    }));
    setEvents(dataWithImages);
    setFilteredEvents(dataWithImages); 
    props.loderFunction(false);
  }

  const years = ["All", ...new Set(events.map(event => event.year.toString()))];

  return (
    <section className="mt-5">
      <DropdownButton id="dropdown-basic-button" title={`Year: ${selectedYear}`} variant="secondary" onSelect={handleSelect}>
        {years.map((year, index) => (
          <Dropdown.Item key={index} eventKey={year}>
            {year}
          </Dropdown.Item>
        ))}
      </DropdownButton>
      <section className="mt-4"></section>
      <div className="band">
        {currentImages.map((item, ind) => (
          <div className={`item-${ind}`} key={ind} id="multiple">
            <a className="my-card">
              <img src={item.image} alt={item.blog_title} className="slider-image" onClick={() => handleImageClick(item.page_url)}/>
              <div className="event_year">{item.year}</div> 
              <div className="event-title">{item.blog_title}</div>
            </a>
          </div>
        ))}
      </div>
      <div className="pagination-controls">
        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
          <i class="fa-solid fa-chevron-left"></i>
        </button>
        <div className="pagination-dots">
          {Array.from({ length: Math.ceil(filteredEvents.length / itemsPerPage) }, (_, index) => (
            <span key={index} className={`dot ${currentPage === index + 1 ? 'active' : ''}`} 
              onClick={() => setCurrentPage(index + 1)}>
            </span>
          ))}
        </div>
        <button onClick={handleNextPage} disabled={currentPage === Math.ceil(filteredEvents.length / itemsPerPage)}>
          <i class="fa-solid fa-chevron-right"></i> 
        </button>
      </div>
    </section>
  );
}
export default Tour_Trail;
